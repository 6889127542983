body {
  /*
  @include mq(false, $bp-mobile-max) {
    .page-body.fix-sticky-header {
      padding-top: 0 !important;
    }

    &.has-fixed-header {
      .sticky-header {
        top: 0 !important;
        box-shadow: $bs-light;
      }
    }
  }

  @include mq($bp-desktop-min) {*/
}

body.has-fixed-header .sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: all 250ms linear;
}
